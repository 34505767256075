import { FieldValues, useForm } from 'react-hook-form';
import FormTemplate from 'templates/FormTemplate';
import { CheckCircle } from 'lucide-react';

import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_ORDER } from 'constants/pages';
import ControlledAttachFile from 'components/ControlledForms/ControlledAttachFile';
import { yupResolver } from '@hookform/resolvers/yup';
import { OrderProcessSchema } from 'components/resolvers';
import FormInputTemplate from 'templates/FormInputTemplate';
import { isEmpty } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { ADD_TASK_TYPES, ATTACH_WORK_DRAW } from 'graphql/mutation/task';
import { GET_ORDER_BY_CODE } from 'graphql/query';
import { ITask } from 'types';
import { TECHNICAL_CONDITIONS } from 'constants/type';
import { uploadToS3 } from 'utils';
import { useNotifications } from 'providers';

const OrderProcess = () => {
  let history = useNavigate();
  const { code } = useParams<{ code: string }>();
  const { addNotification } = useNotifications();
  const { control, handleSubmit, watch, setValue } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(OrderProcessSchema) as any,
    defaultValues: {
      step: 1,
      files: [],
      workingDraw: false,
    },
  });

  const { step, workingDraw, files, task } = watch();

  const { loading } = useQuery(GET_ORDER_BY_CODE, {
    skip: !code,
    variables: { code },
    onCompleted: (data) => {
      let task = data.getOrderByCode.tasks?.find(
        (task: ITask) => task.type === TECHNICAL_CONDITIONS,
      );
      if (!task?.detailedPicture) {
        history(PAGE_ORDER + `/${code}`);
      }
      if (task) {
        setValue('task', task);
      }
    },
  });

  const [attachWorkDraw, { loading: attaching }] = useMutation(ATTACH_WORK_DRAW, {
    onCompleted: async (data) => {
      if (!isEmpty(files)) {
        for (const file of files) {
          const uploadUrl = data.attachWorkDraw.uploads.find((url: string) => {
            const normalizedUrl = decodeURIComponent(url)
              .toLowerCase()
              .replace(/[\s_]+/g, '');
            const normalizedFileName = file.name.toLowerCase().replace(/[\s_]+/g, '');
            return normalizedUrl.includes(normalizedFileName);
          });

          if (uploadUrl) {
            try {
              await uploadToS3(uploadUrl, file.content, file);
            } catch (uploadError) {
              addNotification(`Failed to upload ${file.name}`, 'error');
              console.error(`Error uploading file ${file.name}:`, uploadError);
            }
          } else {
            addNotification(`Upload URL not found for ${file.name}`, 'error');
          }
        }
      }
      addNotification('Амжилттай', 'success');
      setValue('step', 2);
    },
  });

  const [addTaskTypes, { loading: adding }] = useMutation(ADD_TASK_TYPES, {
    onCompleted: (data) => {
      addNotification('Амжилттай', 'success');
      setValue('step', 2);
    },
  });

  const onSubmit = (data: FieldValues) => {
    if (step === 1) {
      if (workingDraw) {
        addTaskTypes({
          variables: {
            id: task.id,
            types: ['workingDraw'],
          },
        });
      } else {
        attachWorkDraw({ variables: { id: task.id, upload: files[0].name, type: 'workingDraw' } });
      }
    } else if (step === 2) {
      history(PAGE_ORDER);
    }
  };

  const goBackStep = () => {
    if (step === 2) {
      setValue('step', 1);
    } else if (step === 3) {
      setValue('step', 1);
    } else if (step === 1) {
      history(PAGE_ORDER);
    }
  };

  return (
    <FormTemplate
      totalSteps={2}
      onBack={goBackStep}
      handleSubmit={handleSubmit}
      activeStep={step}
      loading={attaching || loading || adding}
      onSubmit={onSubmit}
    >
      <FormInputTemplate isHide={step === 1} headerText="Ажлын зураг">
        {step === 1 && (
          <>
            {isEmpty(files) && (
              <div
                className={`w-full gap-2 flex items-center p-4 font-semibold text-sm text-start rounded-lg cursor-pointer  border ${
                  workingDraw
                    ? '   border-green-500 bg-green-100  text-green-800'
                    : '  border-gray-400'
                }  mb-2 text-sm  `}
                onClick={() => setValue('workingDraw', !workingDraw)}
              >
                <div className="checkbox-wrapper-18">
                  <div className="round">
                    <input checked={workingDraw} type="checkbox" id="checkbox-18" />
                    <label htmlFor={'checkbox-18'}></label>
                  </div>
                </div>

                <span>Та ажлын зураг төсөл хийлгэх үү</span>
              </div>
            )}

            {isEmpty(files) && !workingDraw && (
              <div className="mb-2">
                <span className="text-sm text-gray-500 mb-4 ">
                  Эсвэл та өөрийн ажлаа зургаа хавсаргаж үнийн санал авах боломжтой
                </span>
              </div>
            )}

            {!workingDraw && (
              <ControlledAttachFile
                control={control}
                buttonText="Ажлийн зураг хавсаргах"
                name="files"
              />
            )}
          </>
        )}
      </FormInputTemplate>
      {step === 2 && (
        <div className=" flex w-full min-h-40 mt-24 items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <CheckCircle className=" animate-pulse text-green-500 h-20 w-20" />
            <p className="font-semibold mt-10 px-4 ">
              {isEmpty(files)
                ? 'Таны ажлын захиалга амжиллттай нийтлэгдлээ'
                : 'Таны ажлын зураг амжиллттай хавсрагдлаа'}
            </p>
            <p className="font-medium  text-sm mt-4 "></p>
          </div>
        </div>
      )}
    </FormTemplate>
  );
};

export default OrderProcess;
