import { useNavigate } from 'react-router-dom';
import { MdOutlineArrowBack } from 'react-icons/md';
import { PAGE_INDEX, PAGE_REGISTER, PAGE_FORGOT_PASSWORD, PAGE_ORDER } from 'constants/pages';
import ControlledInput from 'components/ControlledForms/ControlledInput';
import { FieldValues, useForm } from 'react-hook-form';
import Button from 'components/Button';
import FlexRowTemplate from 'templates/FlexRowTemplate';
import { useMutation } from '@apollo/client';
import { LOGIN } from 'graphql/mutation';
import { useContext } from 'react';
import { AuthContext, useNotifications } from 'providers';
import { loginSchema } from 'components/resolvers';
import { yupResolver } from '@hookform/resolvers/yup';

const Index = () => {
  let history = useNavigate();
  const path = window.location.pathname;
  const { authenticate } = useContext(AuthContext);
  const { addNotification } = useNotifications();
  const { control, handleSubmit, setError } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(loginSchema) as any,
  });

  const [login, { loading }] = useMutation(LOGIN, {
    onCompleted: (data) => {
      authenticate(data.login.token, () => {
        if (path.match('order')) {
          return history(path);
        }

        history(PAGE_ORDER);

        window.location.reload();

        addNotification('Амжилттай нэвтэрлээ!', 'success');
      });
    },
    onError: (error) => {
      setError('phone', { type: 'server', message: error.message });
    },
  });

  const onSubmit = (data: FieldValues) => {
    const { phone, password } = data;
    login({ variables: { phone, password } });
  };

  const forgotPassword = () => {
    history(PAGE_FORGOT_PASSWORD);
  };

  return (
    <div className="grid  grid-cols-1 md:grid-cols-2 h-screen">
      <form>
        <div className="w-full p-8 ">
          <MdOutlineArrowBack
            className="w-7 h-7 text-gray-800"
            onClick={() => history(PAGE_INDEX)}
          />
        </div>
        <FlexRowTemplate>
          <div className=" grid w-full text-center mb-4">
            <span className="text-xl font-semibold mb-4 text-gray-800">
              Ажил захиалагчдад зориулсан холболт
            </span>
            <span className="text-sm text-gray-500 ">
              Бүртгэл үүсгэх эсвэл нэвтэрч, захиалгаа өгч удирдах боломжтой.
            </span>
          </div>
        </FlexRowTemplate>
        <FlexRowTemplate>
          <ControlledInput
            control={control}
            placeholder="Утасны дугаар оруулна уу"
            name="phone"
            type="text"
          />
        </FlexRowTemplate>
        <FlexRowTemplate>
          <ControlledInput
            control={control}
            placeholder="Нууц оруулна уу"
            name="password"
            type="password"
          />
        </FlexRowTemplate>
        <FlexRowTemplate>
          <div className=" grid w-full text-end mb-1">
            <span className="text-sm text-gray-800 " onClick={forgotPassword}>
              Нууц үгээ мартсан
            </span>
          </div>
        </FlexRowTemplate>

        <FlexRowTemplate>
          <Button loading={loading} text="Үргэлжлүүлэх" onClick={handleSubmit(onSubmit)} />
        </FlexRowTemplate>
        <FlexRowTemplate>
          <div className=" w-full divider divider-horizontal">
            <span className="text-sm text-gray-400">Эсвэл</span>
          </div>
        </FlexRowTemplate>
        <FlexRowTemplate>
          <Button text="Бүртгүүлэх" onClick={() => history(PAGE_REGISTER)} isSolid />
        </FlexRowTemplate>
      </form>

      <div className=" hidden   md:flex ">
        <img src="/images/login.jpg" alt="login" className="bg-contain bg-center h-dvh w-dvw" />
      </div>
    </div>
  );
};
export default Index;
