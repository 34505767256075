import { useQuery } from '@apollo/client';
import { GET_OFFER_PARTNER } from 'graphql/query';
import { IOffer } from 'types/offer';

type Props = {
  offer: IOffer;
};

const PartnerInfo = ({ offer }: Props) => {
  const { data: partner, loading } = useQuery(GET_OFFER_PARTNER, {
    skip: !offer,
    variables: { id: offer?.id },
  });

  return (
    <div>
      <div className="grid grid-cols-6 gap-4 text-xs mt-2 text-gray-500">
        <div className="col-span-2">
          <span>Байгууллагын нэр:</span>
        </div>
        <div className="col-span-4">
          <span className="font-semibold text-end">{partner?.getOfferPartner?.name}</span>
        </div>
      </div>

      <div className="grid grid-cols-6 gap-4 text-xs mt-2 text-gray-500">
        <div className="col-span-2">
          <span>Байгууллагын утас:</span>
        </div>

        <div className="col-span-4">
          <span className="font-semibold text-end">{partner?.getOfferPartner?.phone}</span>
        </div>
      </div>
      <div className=" w-full my-2 divider divider-horizontal" />
      <div
        className="flex items-center p-2  text-green-800 border border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
        role="alert"
      >
        <div className="flex items-center ">
          <svg
            className="flex-shrink-0 inline w-5 h-5 me-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
          </svg>
          <span className="font-semibold text-xs ">
            Таны ажилд өгсөн үнийн саналуудаас та энэ компанийг сонгосон байна. Дээрхи мэдээллээр
            холбогдон гэрээгээ байгуулна уу. Амжилт хүсье.
          </span>
        </div>
      </div>
    </div>
  );
};

export default PartnerInfo;
